function senp(plUrl, elementName = null, naMode, pageLevelDefinition = null, infiniteScrollEvent = null, purpose = 'all') {

    // Set and check for plUrl property. Cannot have a pixel without a plUrl
    this.plUrl = plUrl;

    if(naMode){
        this.naMode = naMode;
    }

    this.pageLevelDefinition = pageLevelDefinition;

    if(pageLevelDefinition.engagementContainer !== undefined) {

        this.engagementContainer = pageLevelDefinition.engagementContainer;
    }else {

        this.engagementContainer = "article.w-article";
    }

    this.uuid = this.generateUUID();

    this.connectionSpeed = this.getConnectionSpeed();
    
    if(purpose === 'init'){

        return true;

    }else if(purpose === 'visit'){

        if(this.plUrl){

            this.sendPl();
        }

    }else if(purpose === 'markers') {

        if(this.plUrl){

            if(elementName=='article'){

                
                var element = document.body;

                var elements = document.querySelectorAll(this.engagementContainer);
                if (elements.length > 0) {

                    element = elements[elements.length - 1];
                }

                this.createPageDepthMarkers(element);

            }

            if(infiniteScrollEvent !== null){

                this.infiniteScrollEvent = infiniteScrollEvent;
                this.listenForInfiniteScroll(infiniteScrollEvent);
            }

            this.createEngagedPageTimers();
        }
    }else{
        if(this.plUrl){

            this.sendPl();

            if(elementName=='article'){

                
                var element = document.body;

                var elements = document.querySelectorAll(this.engagementContainer);
                if (elements.length > 0) {

                    element = elements[elements.length - 1];
                }

                this.createPageDepthMarkers(element);

            }

            if(infiniteScrollEvent !== null){

                this.infiniteScrollEvent = infiniteScrollEvent;
                this.listenForInfiniteScroll(infiniteScrollEvent);
            }

            this.createEngagedPageTimers();
        }
    }

}


senp.prototype.setMarkers = function() {

    if(this.plUrl){

        if(elementName == 'article'){

            
            var element = document.body;

            var elements = document.querySelectorAll(this.engagementContainer);
            if (elements.length > 0) {

                element = elements[elements.length - 1];
            }

            this.createPageDepthMarkers(element);

        }

        if(infiniteScrollEvent !== null){

            this.infiniteScrollEvent = infiniteScrollEvent;
            this.listenForInfiniteScroll(infiniteScrollEvent);
        }

        this.createEngagedPageTimers();
    }
}


senp.prototype.sendPl = function(type = null, typeValue = null) {

    var plUrl = this.plUrl;
    var referrer = document.referrer;
    var xhttp = new XMLHttpRequest();

    var hostname = window.location.hostname;
    if(!hostname.startsWith("www.")){
        hostname = "www." + hostname;
    }

    // define basic url
    var url = "https://sentinelbi.com/" + hostname + "/" + plUrl + "?time=" + Date.now() + "";

    // add permalink to url
    url = url + "&permalink=" + "/" + window.location.pathname.replace(/(^\/+|\/+$)/g, '') + '/';

    // add referrer to url, generated in real time
    if(referrer){
        url = url + "&referrer=" + referrer;
    }

    // add isActiveSession to url
    if(sessionStorage){

        if(sessionStorage.getItem('sn_ssTr')){
            url = url + "&isActiveSession=" + sessionStorage.getItem('sn_ssTr');
        }else{
            url = url + "&isActiveSession=" + 0;
        }

    }else{

        url = url + "&isActiveSession=" + 0;
    }
    

    if (this.connectionSpeed) {
        // add connection speed
        url = url + "&connSpeed=" + this.connectionSpeed;
    }

    if (this.naMode) {

        url = url + "&naMode=" + this.naMode;
    }

    if(this.pageLevelDefinition){
        
        if(this.pageLevelDefinition.intent){

            url = url + "&intent=" + encodeURIComponent(this.pageLevelDefinition.intent);
        }

        if(this.pageLevelDefinition.contentType){

            url = url + "&contentType=" + encodeURIComponent(this.pageLevelDefinition.contentType);
        }

        if(this.pageLevelDefinition.primaryTag){

            url = url + "&primaryTag=" + encodeURIComponent(this.pageLevelDefinition.primaryTag);
        }

        if(this.pageLevelDefinition.primaryCategory){

            url = url + "&primaryCategory=" + encodeURIComponent(this.pageLevelDefinition.primaryCategory);
        }

        if(this.pageLevelDefinition.networkCategory){

            url = url + "&networkCategory=" + encodeURIComponent(this.pageLevelDefinition.networkCategory);
        }

        if(this.pageLevelDefinition.adsTemplate){

            url = url + "&template=" + encodeURIComponent(this.pageLevelDefinition.adsTemplate);
        }

        if(this.pageLevelDefinition.articleType){

            url = url + "&articleType=" + encodeURIComponent(this.pageLevelDefinition.articleType);
        }

        if(this.pageLevelDefinition.userRef !== undefined){

            url = url + "&userRef=" + encodeURIComponent(this.pageLevelDefinition.userRef);
        }

        if(this.pageLevelDefinition.loggedIn !== undefined){

            url = url + "&loggedIn=" + encodeURIComponent(this.pageLevelDefinition.loggedIn);
        }

        if(this.pageLevelDefinition.plan !== undefined){

            url = url + "&plan=" + encodeURIComponent(this.pageLevelDefinition.plan);
        }

        if(this.pageLevelDefinition.referrerOverride){

            url = url + "&referrerOverride=" + encodeURIComponent(this.pageLevelDefinition.referrerOverride);
        }
    }

    // check for type and use if not pre-defined rely on referrer
    if(!type){

        type = "pageVisit";

        // check referrer domain to update and set type
        if (referrer && referrer.trim() !== ""){

            var domain = window.location.hostname;
            var referrerDomain = null;

            if(referrer){
                referrerDomain = (new URL(referrer)).hostname;
            }

            if (referrerDomain === domain) {

                type = "pageView";
            } else {

                type = "pageVisit";
            }
        }
    }

    url = url + "&type=" + type;

    if(typeValue!==null){

        url = url + "&typeValue=" + typeValue;
    }

    url = url + "&uuid=" + this.uuid;

    xhttp.open("GET", url, true);
    xhttp.send();
}

senp.prototype.sendVideoPl = function(identifier, type, clientData, message = null, value = null, data = null) {

    var plUrl = this.plUrl;
    var referrer = document.referrer;
    var xhttp = new XMLHttpRequest();

    var hostname = window.location.hostname;
    if(!hostname.startsWith("www.")){
        hostname = "www." + hostname;
    }

    // define basic url
    var url = "https://sentinelbi.com/" + hostname + "/video/" + plUrl + "?time=" + Date.now() + "";

    // add referrer to url, generated in real time
    url = url + "&referrer=" + referrer;

    // add isActiveSession to url
    url = url + "&isActiveSession=" + (sessionStorage.getItem("sn_ssTr") ?? 0);

    if (this.connectionSpeed) {
        // add connection speed
        url = url + "&connSpeed=" + this.connectionSpeed;
    }

    if(this.naMode){
        
        url = url + "&naMode=" + this.naMode;
    }

    if(this.pageLevelDefinition){
        
        if(this.pageLevelDefinition.intent){

            url = url + "&intent=" + encodeURIComponent(this.pageLevelDefinition.intent);
        }

        if(this.pageLevelDefinition.contentType){

            url = url + "&contentType=" + encodeURIComponent(this.pageLevelDefinition.contentType);
        }

        if(this.pageLevelDefinition.primaryTag){

            url = url + "&primaryTag=" + encodeURIComponent(this.pageLevelDefinition.primaryTag);
        }

        if(this.pageLevelDefinition.primaryCategory){

            url = url + "&primaryCategory=" + encodeURIComponent(this.pageLevelDefinition.primaryCategory);
        }

        if(this.pageLevelDefinition.networkCategory){

            url = url + "&networkCategory=" + encodeURIComponent(this.pageLevelDefinition.networkCategory);
        }
    }

    // add type to url
    url = url + "&type=" + type;

    // add placement to url
    url = url + "&placement=" + identifier;

    // add fileName to url (data - videoUrl)
    if(data && data.videoUrl !== undefined){
        
        url = url + "&fileName=" + data.videoUrl;
    }

    // add isAutoPlay to url (data - isAutoplay - true or false)
    if(data && data.isAutoplay !== undefined){

        url = url + "&isAutoPlay=" + ((data.isAutoplay) ? "1" : "0");
    }

    // add permalink to url (clientData - clientData - url)
    if(clientData.clientData && clientData.clientData.url !== undefined) {
        
        url = url + "&permalink=" + "/" + clientData.clientData.url.replace(/(^\/+|\/+$)/g, '') + "/";
    }

    // add template to url (clientData - clientData - template)
    if(clientData.clientData && clientData.clientData.template !== undefined) {
        
        url = url + "&template=" + clientData.clientData.template;
    }
    
    // add source to url (clientData - clientData - source)
    if(clientData.clientData && clientData.clientData.source !== undefined) {

        url = url + "&source=" + clientData.clientData.source;
    }

    url = url + "&uuid=" + this.uuid;

    xhttp.open("GET", url, true);
    xhttp.send();
}

senp.prototype.createPageDepthMarkers = function(element) {
    
    var elementStartPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
    var elementHeight = element.offsetHeight;
    var snObj = this;

    var depthMarkerPercentages = [0, 20, 40, 60, 80, 100];
    depthMarkerPercentages.forEach(function(percentage) {

        setupPercentMarker(snObj, elementStartPos, elementHeight, percentage);
    });
}

function setupPercentMarker(snObj, elementStartPos, elementHeight, percentage) {
    
    var offsetHeight = (elementHeight * percentage / 100) + elementStartPos;
    
    function handleScroll() {
        
        if ((window.scrollY + window.innerHeight) > offsetHeight) {
            
            snObj.percentMarkerHandler(percentage);
            window.removeEventListener('scroll', handleScroll);
        }
    }
    
    window.addEventListener('scroll', handleScroll);
}

senp.prototype.percentMarkerHandler = function(percentage) {
    
    this.sendPl("engagedDepth",percentage);
}

senp.prototype.createEngagedPageTimers = function() {
    
    const maxEngagedTime = 30 * 60 * 1000;
    var totalTimeEngaged = 0;
    var timerPos = 0;
    var timers = [5000,10000,15000,25000,35000,45000,65000,85000,105000,145000,185000,225000,305000,385000,465000,625000,785000,945000,1265000,1585000,1905000];
    var snObj = this;
    var timerId;
    var pausedTimer = false;
    
    function recursiveTimeout() {
        
        if(totalTimeEngaged > maxEngagedTime){
            return;
        }
        
        timerId = setTimeout(recursiveTimeout, timers[timerPos]-totalTimeEngaged);
        
        if(!pausedTimer){
            
            if(totalTimeEngaged>0){
                
                snObj.engagedTimeHandler(timers[timerPos-1] / 1000);
            }
            
            totalTimeEngaged = timers[timerPos];
            timerPos += 1;
        }
        
    }
    
    function pauseTimers() {
        
        clearTimeout(timerId);
        pausedTimer = true;
    }
    
    function resumeTimers() {
        
        recursiveTimeout();
        pausedTimer = false;
    }
    
    document.addEventListener("visibilitychange", function() {
        
        if (document.visibilityState === "hidden") {
            
            pauseTimers();
        } else {
            
            resumeTimers();
        }
    });
    
    recursiveTimeout();
}

senp.prototype.engagedTimeHandler = function(seconds) {
    
    this.sendPl("engagedSeconds", seconds);
}

senp.prototype.getConnectionSpeed = function() {
    
    var connectionSpeed = null;
    if(navigator.connection){
        connectionSpeed = navigator.connection.downlink;
    }
    
    return connectionSpeed;
}

senp.prototype.generateUUID = function() {
    
    var uuid;
    // Check if crypto.randomUUID() is supported
    if (typeof crypto.randomUUID === 'function') {
        // Use crypto.randomUUID() if supported
        uuid = crypto.randomUUID();
    } else {
        // Fallback method for generating UUIDs
        function fallbackUUID() {
            // Implementation of fallback UUID generation algorithm
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
        
        // Use the fallback method to generate UUID
        uuid = fallbackUUID();
    }
    
    return uuid;
}

senp.prototype.listenForInfiniteScroll = function(infiniteScrollEvent) {
    
    // Listen for infinite scroll events
    window.addEventListener(infiniteScrollEvent, (event) => {
        
        this.sendPl("pageView");
    });
}

window.senp = senp;